.fa-btn {
    margin-right: 8px;
}

.app-screen {
	.btn {
		transition: background-color 0.2s ease;
	}

	.btn-link {
		font-weight: 500;
	}
}

.btn {
	border: 0 !important;
	border-radius: 4px;
	font-weight: 700;
	text-transform: uppercase;
	text-decoration: none !important;
	font-size: 12px;
	padding: 9px 22px 8px 22px;
}
.btn-sm {
	padding: 5px 10px 5px 10px;
}
.btn-xs {
	padding: 3px 7px 3px 7px;
	font-size: 11px;
}
.btn-xxs {
	padding: 2px 5px 2px 5px;
	font-size: 10px;
	text-transform: none;
}
.btn-full {
	width: 100%;
	display: inline-block;
}
.btn-half {
	width: calc(50% - 3px);
	display: inline-block;
}
.btn-default {
	background: #e5e5e5;
	color: #666 !important;
}
.btn-default:hover, .btn-default:focus, .btn-default:active {
	background: #ddd !important;
}
.btn-primary {
	background: #aa72bf;
	background: linear-gradient(to bottom,  #aa72bf 0%,#7d548c 100%);

	&:hover, &:focus, &:active {
		background: #864d99 !important;
	}
}

.btn-secondary {
	color: #fff;
	@include gradientSecondary;

	&:hover, &:focus, &:active {
		color: #fff;
		background: #1c1e22 !important;
	}
}

.btn-success {
	color: #456614;
	@include gradientSuccess;

	&:hover, &:focus, &:active {
		background: #88c73f !important;
	}
}

.btn-danger {
	color: #fff;
	@include gradientDanger;

	&:hover, &:focus, &:active {
		background: #e6413d !important;
	}
}

.btn-white {
	color: #333 !important;
	background: #fff;
	background: linear-gradient(to bottom, #fff 0%,#ddd 100%);

	&:hover, &:focus, &:active {
		color: #333 !important;
		background: #eee !important;
	}
}

.input-group button { border-radius: 0 3px 3px 0; }
.input-group button.two-lines { padding: 2px 17px 1px; }
	.input-group button span { display: block; white-space: nowrap; font-size: 10px; }

.btn-group {
	.btn + .dropdown-toggle {
		border-left: 1px rgba(0, 0, 0, 0.3) solid !important;
	}

	.dropdown-menu {
		border-radius: 5px;

		li {
			a {
				cursor: pointer;
				font-size: 13px;
				text-decoration: none;
			}
		}
	}
}

.btn[disabled] { opacity: 0.3; }

a[class^="btn"] {
	&:disabled {
		opacity: 0.5;
		cursor: not-allowed;
	}
}
