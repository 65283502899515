@import 'colors';

@import 'mixins/gradients';
@import 'mixins/shadows';

$media-xxl-width: 1600px;
$media-xl-width: 1200px;
$media-lg-width: 991px;
$media-md-width: 767px;
$media-sm-width: 440px;

$main-dark-background: #31353c;
$main-background: #f7f8f8;
$white: #fff;

$text-black: #333;

$header-height: 46px;
$header-height-md: 40px;

$border-color: #eee;

$border-radius: 4px;

$sidebar-width: 260px;
$sidebar-xl-width: 260px;
$sidebar-collapsed-width: 49px;

$sidebar-hover-background: #383c45;
$sidebar-borders-color: #4D535D;

$shadow-default: 0 0 25px rgba(0, 0, 0, 0.07);

%gradient-violet {
    background: #aa72bf;
    background: -moz-linear-gradient(top,  #aa72bf 0%, #7d548c 100%);
    background: -webkit-linear-gradient(top,  #aa72bf 0%,#7d548c 100%);
    background: linear-gradient(to bottom,  #aa72bf 0%,#7d548c 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#aa72bf', endColorstr='#7d548c',GradientType=0 );
}

%gradient-violet-important {
    background: #aa72bf !important;
    background: -moz-linear-gradient(top,  #aa72bf 0%, #7d548c 100%) !important;;
    background: -webkit-linear-gradient(top,  #aa72bf 0%,#7d548c 100%) !important;;
    background: linear-gradient(to bottom,  #aa72bf 0%,#7d548c 100%) !important;;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#aa72bf', endColorstr='#7d548c',GradientType=0 ) !important;;
}
