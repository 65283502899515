.navbar {
	font-size: 16px;
	font-weight: 300;
	margin-bottom: 40px;

	.navbar-brand {
		font-size: 28px;

		i {
			color: #f4645f;
		}
	}

	.navbar-nav {
		padding-top: 0px;
		li a.v-link-active {
			text-decoration: underline;
		}
	}

	.dropdown-menu {
		li {
			font-size: 14px;

			a {
				font-weight: 300;
				line-height: 25px;
				vertical-align: middle;
			}
		}
	}
}

.buttons-nav-helper {
	height: 46px;
	padding: 10px 0;
	width: 100%;

	ul {
		list-style: none;
		margin: 0;
		padding: 0;

		li {
			margin: 0 5px 0 0;
			padding: 0;
			float: right;
			height: 27px;

			a, button {
				display: block;
				padding: 5px 7px;
				background: #4d525a;
				color: #fff;
				border-radius: 4px;
				cursor: pointer;
				text-decoration: none;
				border: 0;

				&:hover {
			        text-decoration: none;
			        background: #6B717B;
			    }

			    &[disabled] {
					cursor: not-allowed;
					opacity: 0.5;
				}
			}

			&.left {
				float: left;
			}
		}

		li.separator {
			width: 1px;
			margin: 0 7px 0 2px;
			background: #4D535D;
		}

		li.separator + li.separator {
			display: none;
		}
	}
}
